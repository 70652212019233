<template>
  <v-app id="inspire">
    <v-container>
      <top-bar :navpos="items" />
      <v-main>
        <h1>{{  $t("errorPages.notFound.head")  }}</h1>
        <br /><br />
        <p>{{  $t("errorPages.notFound.p1")  }}</p>
        <p>{{  $t("errorPages.notFound.p2")  }}</p>
        <p>{{  $t("errorPages.notFound.p3")  }}</p>
        <div style="width: 200px; margin: 30px auto 0 auto">
          <pre id="rofl">
ROFL:ROFL:LOL:ROFL:ROFL
           ^    
  L   /---------
 LOL===       []\
  L    \         \
        \_________]
          I    I
        ----------/
    
        </pre>
        </div>
      </v-main>
      <footer-bar />
    </v-container>
  </v-app>
</template>
<script>
import TopBar from "../components/TopBar.vue";
import FooterBar from "../components/Footer.vue";

export default {
  components: { TopBar, FooterBar },
  name: "Main",
  props: {},
  chMap: [
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 43, 78],
    [14, 15, 16, 17, 18, 19, 20, 21, 22, 59, 61]
  ],
  chMapLength: 2,
  isBlank: true,
  isBlankCounter: 1,
  nonBlankTemplate: ["R", "O", "F", "L", ":", "R", "O", "F", "L", "L", "L"],
  rofl: null,
  data() {
    return {
      items: [
        {
          text: this.$t("nav.main"),
          disabled: false,
          href: "/main"
        }
      ]
    };
  },
  methods: {
    doRofl() {
      for (var i = 0; i < this.chMapLength; i++) {
        this.isBlank = !!(2 & this.isBlankCounter++);
        for (var j = 0; j < this.chMap[i].length; j++) {
          var chReplace;
          if (!this.isBlank) {
            chReplace = " ";
          } else {
            chReplace = this.nonBlankTemplate[j];
          }
          this.rofl = this.rofl.replaceAt(this.chMap[i][j], chReplace);
        }
        document.getElementById("rofl").innerHTML = this.rofl;
      }
    }
  },
  computed: {},
  mounted() {
    this.rofl = document.getElementById("rofl").innerHTML;
    this.chMap = [
      [0, 1, 2, 3, 4, 5, 6, 7, 8, 43, 78],
      [14, 15, 16, 17, 18, 19, 20, 21, 22, 59, 61]
    ];
    this.chMapLength = this.chMap.length;
    this.isBlank = true;
    this.isBlankCounter = 1;
    this.nonBlankTemplate = [
      "R",
      "O",
      "F",
      "L",
      ":",
      "R",
      "O",
      "F",
      "L",
      "L",
      "L"
    ];
    setInterval(this.doRofl, 80);
  }
};
String.prototype.replaceAt = function (index, character) {
  return (
    this.substr(0, index) + character + this.substr(index + character.length)
  );
};
</script>